import HttpRequest from './HttpRequest'

class KnowledgeBaseCategoryProvider extends HttpRequest {
  findAll () {
    return this.get('/knowledge-base-category')
  }

  findOne (id) {
    return this.get(`/knowledge-base-category/${id}`)
  }

  create (payload) {
    return this.post('/knowledge-base-category', payload)
  }

  update (id, payload) {
    return this.patch(`/knowledge-base-category/${id}`, payload)
  }

  softDelete (id) {
    return this.delete(`/knowledge-base-category/${id}`)
  }

  hardDelete (payload) {
    return this.post('/knowledge-base-category/delete', payload)
  }

  paginate (page = 1, limit = 10, query) {
    return this.get(`/knowledge-base-category?page=${page}&limit=${limit}`, query)
  }
}

export default KnowledgeBaseCategoryProvider
