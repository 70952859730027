<template>
  <b-sidebar
    :visible="isSidebarShow"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    width="70%"
    @hidden="isSidebarShow = false"
    @change="updateIsSidebarActive($event)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Knowledge Base Selected
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide" />
      </div>
      <div class="d-flex justify-content-end m-1">
        <b-button
          class="mr-1"
          :disabled="deleted.length <= 0"
          variant="danger"
          @click="onDelete()">
          Delete ({{ deleted.length }})
        </b-button>
        <b-button
          variant="success"
          :disabled="selectedAvalibelSave.length <= 0"
          @click="onSubmit()">
          Save ({{ selectedAvalibelSave.length }})
        </b-button>
      </div>

      <!-- BODY -->
      <b-row class="mx-1">
        <b-col
          sm="12"
          md="4">
          <SelectField
            v-model="categoryLevelValue.categorySearchLvlOne"
            :disabled="!lvlOneOptions || lvlOneOptions.length <= 0"
            :clearable="false"
            name="Category level 1"
            form-label="Category level 1"
            placeholder="Category level 1"
            return-value="id"
            :rules="{ required: true }"
            label-option="name"
            :options="lvlOneOptions"
            @input="selectedCategoryItem($event, 1)"
          />
        </b-col>
        <b-col
          sm="12"
          md="4">
          <SelectField
            v-model="categoryLevelValue.categorySearchLvlTwo"
            :disabled="!lvlTwoOptions || lvlTwoOptions.length <= 0"
            name="Category level 2"
            form-label="Category level 2"
            placeholder="Category level 2"
            :options="lvlTwoOptions"
            return-value="id"
            label-option="name"
            @input="selectedCategoryItem($event, 2)"
          />
        </b-col>
        <b-col
          sm="12"
          md="4">
          <SelectField
            v-model="categoryLevelValue.categorySearchLvlThree"
            :disabled="!lvlThreeOptions || lvlThreeOptions.length <= 0"
            name="Category level 3"
            form-label="Category level 3"
            placeholder="Category level 3"
            :options="lvlThreeOptions"
            return-value="id"
            label-option="name"
            @input="selectedCategoryItem($event, 3)"
          />
        </b-col>
        <b-col
          sm="12"
          md="4">
          <SelectField
            v-model="categoryLevelValue.categorySearchLvlFour"
            :disabled="!lvlFourOptions || lvlFourOptions.length <= 0"
            name="Category level 4"
            form-label="Category level 4"
            placeholder="Category level 4"
            :options="lvlFourOptions"
            return-value="id"
            label-option="name"
            @input="selectedCategoryItem($event, 4)"
          />
        </b-col>
        <b-col
          sm="12"
          md="4">
          <SelectField
            v-model="categoryLevelValue.categorySearchLvlFive"
            :disabled="!lvlFiveOptions || lvlFiveOptions.length <= 0"
            name="Category level 5"
            form-label="Category level 5"
            placeholder="Category level 5"
            :options="lvlFiveOptions"
            return-value="id"
            label-option="name"
            @input="selectedCategoryItem($event, 5)"
          />
        </b-col>
        <b-col
          sm="12"
          md="4">
          <SelectField
            v-model="categoryLevelValue.categorySearchLvlSix"
            :disabled="!lvlSixOptions || lvlSixOptions.length <= 0"
            name="Category level 6"
            form-label="Category level 6"
            placeholder="Category level 6"
            :options="lvlSixOptions"
            return-value="id"
            label-option="name"
            @input="selectedCategoryItem($event, 6)"
          />
        </b-col>
      </b-row>
      <div>
        <b-table
          striped
          hover
          responsive
          :items="knowledgeBases"
          :fields="fields"
        >
          <template #cell(code)="data">
            <span class="font-weight-semibold text-primary">#{{ data.value }}</span>
          </template>
          <template #cell(subject)="data">
            <span class="font-weight-semibold">{{ data.value }}</span>
          </template>
          <template #cell(problemDetail)="data">
            <div
              class="font-weight-semibold wrap-text"
              v-html="$wrapWords(data.value, 10)">
            </div>
          </template>
          <template #cell(knowledgeType)="{ item }">
            <div
              v-if="item && item.knowledgeType"
              class="font-weight-semibold">
              {{
                item.knowledgeType.title
              }}
            </div>
          </template>
          <template #cell(solutionDetail)="data">
            <span
              class="font-weight-semibold wrap-text"
              v-html="$wrapWords(data.value, 10)"></span>
          </template>
          <template #cell(isInCategory)="{ item }">
            <b-badge
              v-if="checkItemInCategory(currentCategoryId ,item.categories)"
              variant="danger">
              Already In Category
            </b-badge>
          </template>
        </b-table>
      </div>
    </template>
  </b-sidebar>
</template>
<script>
import SelectField from '@/components/Form/SelectField.vue'

export default {
  components: {
    SelectField
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => ([])
    },
    deleted: {
      type: Array,
      default: () => ([])
    },
    selectedAvalibelSave: {
      type: Array,
      default: () => ([])
    },
    currentCategoryId: {
      type: [String, Number, null],
      default: null
    },
    categoryLevel: {
      type: Object,
      default: () => ({})
    },
    lvlOneOptions: {
      type: Array,
      default: () => ([])
    },
    lvlTwoOptions: {
      type: Array,
      default: () => ([])
    },
    lvlThreeOptions: {
      type: Array,
      default: () => ([])
    },
    lvlFourOptions: {
      type: Array,
      default: () => ([])
    },
    lvlFiveOptions: {
      type: Array,
      default: () => ([])
    },
    lvlSixOptions: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      fields: [
        { key: 'code', label: 'Code', sortable: true },
        { key: 'subject',
          label: ' Subject',
          thStyle: {
            width: '20rem'
          } },
        { key: 'problemDetail', label: ' Problem' },
        { key: 'solutionDetail', label: ' Solution' },
        { key: 'knowledgeType', label: 'Type' },
        { key: 'isInCategory', label: '' }
        // { key: 'updatedAt', label: 'Updated At', sortable: true },
        // { key: 'updatedBy', label: 'Updated By' },
      ]
    }
  },
  computed: {
    isSidebarShow: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        this.$emit('update:side-bar', val)
      }
    },
    knowledgeBases: {
      get () {
        return this.items
      },
      set (val) {
        this.$emit('update:items', val)
      }
    },
    knowledgeBasesDeleted: {
      get () {
        return this.items
      },
      set (val) {
        this.$emit('update:items', val)
      }
    },
    categoryLevelValue: {
      get () {
        return this.categoryLevel
      },
      set (val) {
        this.$emit('update:category-level', val)
      }
    }
  },
  methods: {
    checkItemInCategory (categoryId, categoriesItems) {
      if (Array.isArray(categoriesItems) && categoriesItems.length > 0) {
        const find = categoriesItems?.find((item) => item?.id === categoryId)
        if (find) {
          return true
        }
      }
      return false
    },
    onDelete () {
      this.$emit('delete-knowledge-category')
    },
    updateIsSidebarActive (val) {
      this.$emit('update:is-sidebar-active', val)
    },
    selectedCategoryItem (event, lvl) {
      this.$emit('selected-category-item', event, lvl)
    },
    onSubmit () {
      this.$emit('add-knowledge-category')
    }
  }
}
</script>
