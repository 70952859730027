<template>
  <ValidationProvider
    #default="validationContext"
    :ref="refName"
    :name="name"
    :vid="name"
    :rules="rules"
    tag="div">
    <b-form-group
      :label="label">
      <b-form-tags
        v-model="innerValue"
        :class="innerClass"
        v-bind="$attrs"
        :state="$getValidationState(validationContext)"
        v-on="{...$listeners}"
      />
      <b-form-invalid-feedback>
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>
    </b-form-group>
  </ValidationProvider>
</template>

<script>
export default {
  props: {
    value: {
      type: [Array],
      required: true,
      default: () => ([])
    },
    name: {
      type: String,
      default: ''
    },
    rules: {
      type: [String, Object],
      default: () => {}
    },
    label: {
      type: String,
      default: ''
    },
    innerClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    innerValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    refName () {
      return `validation-provider-${this.name}`
    }
  }
}
</script>

<style>

</style>
