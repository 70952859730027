<template>
  <b-row class="align-items-end">
    <b-col
      class="mb-1 mb-sm-0"
      sm="12"
      md="6">
      <SearchTextInput
        v-model="searchValue"
        label="Search"
        placeholder="Code, Subject, Problem Detail, Solution Detail"
        @keyup.enter.native="fetchFilter()" />
    </b-col>
    <!-- <b-col
      class="mb-1 mb-sm-0"
      sm="12"
      md="3">
      <SearchTextInput
        v-model="searchTagsValue"
        label="Search Tags"
        placeholder="Tags"
        @keyup.enter.native="fetchFilter()" />
    </b-col> -->
    <b-col
      class="mb-1 mb-sm-0"
      sm="12"
      md="6">
      <StatusDropdown
        :status-filter.sync="statusFilterValue"
        @change-status-filter="fetchFilter()" />
    </b-col>
    <b-col
      cols="6"
    >
      <SelectField
        v-model="searchTypeValue"
        :options="typeOptions"
        name="Type"
        multiple
        placeholder="Search Type"
        :close-on-select="false"
        :deselect-from-dropdown="true"
        form-label="Type"
        label-option="title"
        return-value="id" />
    </b-col>
    <b-col
      cols="6"
    >
      <TagsField
        v-model="searchTagsValue"
        label="Tags"
      />
    </b-col>
    <b-col cols="12">
      <div class="d-flex justify-content-end">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          style="width: 30px"
          class="w-100"
          variant="primary"
          @click="fetchFilter()">
          <feather-icon
            icon="SearchIcon"
            class="mr-50 mr-md-0 mr-lg-0 mr-xl-50" />
          <span class="align-middle">Search</span>
        </b-button>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import SelectField from '@/components/Form/SelectField.vue'
import SearchTextInput from '@/components/Filter/SearchTextInput.vue'
import StatusDropdown from '@/components/Filter/StatusDropdown.vue'
import TagsField from '@/components/Form/TagsField.vue'

export default {
  components: {
    StatusDropdown,
    SearchTextInput,
    TagsField,
    SelectField
  },
  props: {
    typeOptions: {
      type: Array,
      default: () => ([])
    },
    search: {
      type: String,
      default: ''
    },
    searchTags: {
      type: Array,
      default: () => ([])
    },
    statusFilter: {
      type: String,
      default: null
    },
    searchType: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      categoriesEnum: [1, 2]
    }
  },
  computed: {
    searchValue: {
      get () {
        return this.search
      },
      set (val) {
        this.$emit('update:search', val)
      }
    },
    statusFilterValue: {
      get () {
        return this.statusFilter
      },
      set (val) {
        this.$emit('update:status-filter', val)
      }
    },
    searchTagsValue: {
      get () {
        return this.searchTags
      },
      set (val) {
        this.$emit('update:search-tags', val)
      }
    },
    searchTypeValue: {
      get () {
        return this.searchType
      },
      set (val) {
        this.$emit('update:search-type', val)
      }
    }
  },
  methods: {
    fetchFilter () {
      this.$emit('fetch-filter')
    }
  }
}
</script>

<style>

</style>
